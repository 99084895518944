<div class="card">
  <h2 mat-dialog-title class="text-reset">Delete Tag</h2>
  <mat-dialog-content class="text-reset pb-1">
    @if(!techsWithThisTag){
    <p>loading...</p>
    } @else if(techsWithThisTag.length > 0){
    <p>This Tag has attached Techs:</p>
    <ul>
      @for(tech of techsWithThisTag; track tech.id){
      <li>{{ tech.name }}</li>
      }
    </ul>
    <p class="border border-secondary py-1 px-2 rounded">Deleting this tag will remove the tag from these techs.</p>
    } @else{
    <span>No attached techs with this tag</span>
    } @if(isError){
      <span class="text-danger border border-danger py-1 px-2 rounded">An error has occured while trying to delete this tag</span>
    }
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-between">
    <button class="btn btn-outline-secondary mx-1 btn-sm" (click)="close()">Cancel</button>
    <button class="btn btn-outline-danger mx-1 btn-sm" (click)="delete()">Delete</button>
  </mat-dialog-actions>
</div>
