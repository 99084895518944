<div id="container">
  <div class="container card shadow-sm p-3">
    <div id="header" class="form-group position-relative">
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <label for="filter">Filter</label>
          <div>
            <p
              class="btn"
              (click)="toggleCollapse()"
              [title]="toggleCollapseStatus ? 'Expand all genres' : 'Collapse all genres'"
            >
              <em
                class="bi"
                [ngClass]="{
                  'bi-chevron-expand': toggleCollapseStatus,
                  'bi-chevron-contract': !toggleCollapseStatus
                }"
              ></em>
            </p>
            <p class="btn" (click)="resetFilters()" title="Reset filter">
              <em class="bi bi-trash"></em>
            </p>
          </div>
        </div>
        <hr class="row justify-content-center" />
      </div>
      <div class="search-container">
        <em class="bi bi-search"></em>
        <input
          type="text"
          (input)="emitOutput()"
          [(ngModel)]="filter"
          name="filter"
          id="filter"
          placeholder="Search"
        />
      </div>
    </div>
    <hr />
    <div class="genre">
      <app-collapsible buttonLabel="Current selected tags">
        @if (currentEcosystems.length) {
        <div class="d-flex w-100 flex-wrap gap-1">
          @for (ecosystem of allEcosystems; track ecosystem) {
            @if (ecosystem.selected) {
            <app-tag
              [isSelected]="ecosystem.selected!"
              [name]="ecosystem.name"
              [backgroundColor]="ecosystem.backgroundColor!"
              [textColor]="ecosystem.textColor!"
              (click)="changeButtonProperties(ecosystem)"
            >
            </app-tag>
            }
          } 
          </div>
          @if (currentTags.length) {
          <hr class="w-50" />
          }
        }
        <div class="d-flex w-100 flex-wrap gap-1">
          @for (tag of currentTags; track tag) {
          <app-tag
            [isSelected]="tag.selected!"
            [name]="tag.name"
            [backgroundColor]="tag.tagGenre?.backgroundColor!"
            [textColor]="tag.tagGenre?.textColor!"
            (click)="changeButtonProperties(tag)"
          >
          </app-tag>
          }
        </div>
      </app-collapsible>
      <hr />
    </div>
    <div class="genre">
      <app-collapsible buttonLabel="Ecosystem">
        <div class="d-flex w-100 flex-wrap gap-1">
          @for (ecosystem of allEcosystems; track ecosystem) {
          <app-tag
            [isSelected]="ecosystem.selected!"
            [name]="ecosystem.name"
            [backgroundColor]="ecosystem.backgroundColor!"
            [textColor]="ecosystem.textColor!"
            (click)="changeButtonProperties(ecosystem)"
          >
          </app-tag>
        }
        </div>
      </app-collapsible>
      <hr />
    </div>
    @for (genre of allGenres; track genre) {
    <div class="tag-genre-box">
      @if (filterTagByGenre(genre.id)?.length) {
      <app-collapsible buttonLabel="{{ genre.name }}">
        <div class="d-flex w-100 flex-wrap gap-1">
          @for (tag of filterTagByGenre(genre.id); track tag) {
          <app-tag
            [isSelected]="tag.selected!"
            [name]="tag.name"
            [backgroundColor]="tag.tagGenre?.backgroundColor!"
            [textColor]="tag.tagGenre?.textColor!"
            (click)="changeButtonProperties(tag)"
          >
          </app-tag>
          }
        </div>
      </app-collapsible>
      <hr />
      }
    </div>
    } @if ((filterTagWithoutGenre())?.length) {
    <div class="genre">
      <app-collapsible buttonLabel="Tags without genres">
        <div class="d-flex w-100 flex-wrap gap-1">
          @for (tag of filterTagWithoutGenre(); track tag) {
          <app-tag
            [isSelected]="tag.selected!"
            [name]="tag.name"
            [backgroundColor]="tag.tagGenre?.backgroundColor!"
            [textColor]="tag.tagGenre?.textColor!"
            (click)="changeButtonProperties(tag)"
          >
          </app-tag>
          }
        </div>
      </app-collapsible>
      <hr />
    </div>
    }
  </div>
</div>
