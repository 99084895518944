<div class="card">
  <h2 mat-dialog-title class="text-reset">Delete Tag Genre</h2>
  <mat-dialog-content class="text-reset pb-1">
    @if(tagGenre.tags && tagGenre.tags.length > 0){
    <span> Attached Tags: </span>
    <div class="d-flex flex-wrap align-items-center gap-1 mt-1 mb-4">
      @for(tag of tagGenre.tags; track tag.id){
      <app-tag
        [name]="tag.name"
        [backgroundColor]="tagGenre.backgroundColor"
        [textColor]="tagGenre.textColor"
      ></app-tag>
      }
    </div>
    <span class="border border-secondary  py-1 px-2 rounded">These tags will be disconnected from the genre</span>
    } @else {
    <span>No attached tags</span>
    }
    @if(isError){
      <span class="text-danger border border-danger py-1 px-2 rounded">An error has occured while trying to delete this genre</span>
    }
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-between">
    <button class="btn btn-outline-secondary mx-1 btn-sm" (click)="close()">Cancel</button>
    <button class="btn btn-outline-danger mx-1 btn-sm" (click)="delete()">Delete</button>
  </mat-dialog-actions>
</div>
