import { Component, inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { Tag, Tech } from 'src/app/models/models';
import { TechService } from 'src/app/services/tech.service';

@Component({
  selector: 'app-tag-delete-dialog',
  standalone: true,
  imports: [MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose],
  templateUrl: './tag-delete-dialog.component.html',
  styleUrl: './tag-delete-dialog.component.css'
})
export class TagDeleteDialogComponent implements OnInit {

  readonly techService = inject(TechService);
  readonly dialogRef = inject(MatDialogRef<TagDeleteDialogComponent>);
  readonly tag = inject<Tag>(MAT_DIALOG_DATA);
  
  techsWithThisTag: Array<Tech> | null = null;
  isError: boolean = false;

  ngOnInit(): void {
    if (!this.tag.id) return
    this.techService.getTechsByTag(this.tag.id).subscribe(result => {
      this.techsWithThisTag = result.data
    })
  }

  delete() {
    if(!this.tag.id) return
    this.techService.deleteTag(this.tag.id).subscribe({
      next: () => {this.dialogRef.close(this.tag.id)},
      error: (error) => {
        console.error(error);
        this.isError = true
      },
    });
  }

  close() {
    this.dialogRef.close();
  }
}
