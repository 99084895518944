import { Component, inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Tag } from 'src/app/models/models';
import { TagDeleteDialogComponent } from '../tag-delete-dialog/tag-delete-dialog.component';
@Component({
  selector: 'app-tag-delete-button',
  standalone: true,
  imports: [],
  templateUrl: './tag-delete-button.component.html',
  styleUrl: './tag-delete-button.component.css'
})
export class TagDeleteButtonComponent {
  readonly router = inject(Router);
  readonly dialog = inject(MatDialog);
  @Input() tag: Tag | null = null;

  openDeleteModel(): void {
    const dialogRef = this.dialog.open(TagDeleteDialogComponent, {
      data: this.tag,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.router.navigate(['/tag'])
    });
  }
}
