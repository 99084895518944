<div class="container mt-3">
  <div class="container card shadow-sm p-3">
    <div class="d-flex justify-content-between">
      <h3>{{ title }}</h3>
      @if(tag?.id){
      <app-tag-delete-button [tag]="tag || null"></app-tag-delete-button>
      }
    </div>
    <form [formGroup]="tagForm" (submit)="submit()">
      <div class="form-group">
        <label for="name">Name</label><br />
        <input id="name" class="form-control" type="text" name="name" formControlName="name" />
        @if (tagForm.get('name')?.invalid && (tagForm.get('name')?.dirty ||
        tagForm.get('name')?.touched)) {
        <div class="alert alert-danger">
          @if (tagForm.get('name')?.errors?.['required']) {
          <div>Name is required.</div>
          } @if (tagForm.get('name')?.errors?.['minlength']) {
          <div>Name must be at least 4 characters long.</div>
          }
        </div>
        }

        <label for="genre">Genre</label><br />
        <div class="d-flex gap-2">
        <select id="genre" class="form-select flex-grow-1" name="tagGenre" formControlName="tagGenre">
          @for (genre of tagGenres; track genre) {
          <option [value]="genre.id">
            {{ genre.name }}
          </option>
          }
        </select>
        <button class="btn btn-outline-secondary" type="button" (click)="clearGenre()"><span class="bi bi-x-lg"></span></button>
        </div>

        <br />
        <div class="w-100">
          <button class="btn btn-outline-primary mx-1 btn-sm" type="submit">
            <span class="bi bi-save"></span> Save
          </button>
          <button class="btn btn-outline-secondary btn-sm" type="button" (click)="back()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
