import { Component, inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TagGenre } from 'src/app/models/models';
import { TagGenreDeleteDialogComponent } from '../tag-genre-delete-dialog/tag-genre-delete-dialog.component';

@Component({
  selector: 'app-tag-genre-delete-button',
  standalone: true,
  imports: [],
  templateUrl: './tag-genre-delete-button.component.html',
  styleUrl: './tag-genre-delete-button.component.css'
})
export class TagGenreDeleteButtonComponent {
  readonly router = inject(Router);
  readonly dialog = inject(MatDialog);

  @Input() tagGenre: TagGenre | null = null;

  openDeleteModel(): void {
    const dialogRef = this.dialog.open(TagGenreDeleteDialogComponent, {
      data: this.tagGenre,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.router.navigate(['/tag'])
    });
  }
}
