import { Component, inject, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, Subject, Subscription, switchMap } from 'rxjs';
import { Tech } from '../models/models';
import { TechService } from '../services/tech.service';

@Component({
  selector: 'app-similar-techs',
  standalone: true,
  imports: [],
  templateUrl: './similar-techs.component.html',
  styleUrl: './similar-techs.component.css'
})
export class SimilarTechsComponent {
  @Input() name: string | undefined;
  @Input() id: string | undefined;

  private techService = inject(TechService);
  private router = inject(Router);

  similarTechs: Array<Tech> | null = null;

  private nameChangeSubject = new Subject<string | undefined>();
  private nameChangeSub: Subscription | null = null;

  constructor() {
    this.nameChangeSub = this.nameChangeSubject
      .pipe(
        debounceTime(200),
        switchMap((debouncedName) => {
          if (!Boolean(debouncedName?.trim())) {
            this.similarTechs = [];
            return [];
          }
          return this.techService.getTechsSearch(encodeURIComponent(debouncedName!));
        })
      )
      .subscribe({
        next: (techsResponse) => {
          this.similarTechs = (techsResponse.data ?? []).filter(
            (tech) => tech.id !== this.id
          );
        },
        error: (err) => {
          console.error('Error fetching similar techs:', err);
          this.similarTechs = null;
        },
      });
  }
  async onClickGoTo(slug: string) {
    await this.router.navigate([`tech/${slug}`]);
  }
  ngOnDestroy() {
    this.nameChangeSub?.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.similarTechs = null;
    if (changes['name']) {

      // Emit the new input value into the Subject
      this.nameChangeSubject.next(changes['name'].currentValue);
    }
  }
}
