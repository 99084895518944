import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from "rxjs";
import { sluggify } from '../common/utils';
import { Ecosystem, Tag, TechDetail } from '../models/models';
import { TechService } from '../services/tech.service';
import { SimilarTechsComponent } from '../similar-techs/similar-techs.component';
import { TagComponent } from '../tag/tag.component';


@Component({
    selector: 'app-tech-edit',
    templateUrl: './tech-edit.component.html',
    styleUrls: ['./tech-edit.component.css'],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, SimilarTechsComponent, TagComponent]
})

export class TechEditComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private techService = inject(TechService);
  private formBuilder = inject(FormBuilder);

  title: string = "Add Tech";
  tech?: TechDetail;
  tags?: Tag[];
  ecosystems?: Ecosystem[];
  selectedEcosystems?: Ecosystem;
  selectedTags: Tag[] = [];
  slug?: string;
  isSubmitting: boolean = false;
  techForm: FormGroup;

  constructor() {
    this.techForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(1)]],
      slug: ['', [Validators.required, Validators.minLength(1), Validators.pattern(/^[a-z0-9\-]+$/)]],
      url: ['', [Validators.required, Validators.pattern('^https?://.+')]],
      description: ['', [Validators.required, Validators.minLength(10)]],
      ecosystem: [''],
      imageUrl: ['', [Validators.pattern('^https?://.+')]],
    });
  }

  ngOnInit(): void {
    const handler = ({ data }: MessageEvent) => {
      if (data.messageType === 'fileUploaded') {
        this.techForm.patchValue({
          imageUrl: data.payload.url
        });
      }
    }
    window.addEventListener('message', handler);

    this.route.params.subscribe((params) => {
      this.slug = params['slug'];

      this.techService.getTagsWithDetail().subscribe({
        next: (tags) => {
          if (tags.data) this.tags = tags.data;
        }
      })

      this.techService.getEcosystems().subscribe({
        next: (ecosystems) => {
          if (ecosystems.data) this.ecosystems = ecosystems.data;
        }
      })

      if (this.slug) {
        this.title = "Edit Tech"
        this.techService.getTechDetailBySlug(this.slug || '').subscribe({
          next: (tech) => {
            if (tech.data) {
              this.tech = tech.data;
              this.selectedTags = tech.data.tags ?? [];
              this.techForm.patchValue({
                name: tech.data.name,
                description: tech.data.description,
                imageUrl: tech.data.imageUrl,
                url: tech.data.url,
                slug: tech.data.slug,
                ecosystem: tech.data.ecosystems?.[0]?.id ?? ''
              });
            }
            if (tech.error) {
              return;
            }
          },
          error: (e) => {
            console.error(e);
          },
        });
      }
    });
  }


  submit(): void {
    this.isSubmitting = true;
    const updatedTech = {
      ...(this.tech ?? {}),
      name: this.techForm.get("name")?.value,
      slug: this.techForm.get("slug")?.value,
      description: this.techForm.get("description")?.value,
      imageUrl: this.techForm.get("imageUrl")?.value,
      url: this.techForm.get("url")?.value,
      isArchived: this.tech?.isArchived ?? false,
    }

    if (updatedTech?.id) {
      this.techService.putTech(this.tech?.id ?? '-1', updatedTech).subscribe({
        next: async (_) => {
          const tagChanges = this.updateTags(this.tech?.id ?? '');
          const ecoChanges = this.updateEcoSystem(this.tech?.id ?? '', this.techForm.get("ecosystem")?.value);

          const changes = [...tagChanges, ...ecoChanges];
          if (changes.length === 0) {
            this.navigateToPreviousPage();
          }
          let changeSubscription = forkJoin(changes).subscribe({
            next: x => { },
            error: err => {
              console.log(err);
              changeSubscription.unsubscribe();
              this.navigateToPreviousPage()

            },
            complete: () => {
              changeSubscription.unsubscribe();
              this.navigateToPreviousPage()
            }
          })
        },
        error: err => console.error(err)
      });
    }
    else {
      this.techService.postTech(updatedTech).subscribe({
        next: async (techResponse) => {
          if (techResponse.data) {
            this.updateTags(techResponse.data.id ?? '');
            this.updateEcoSystem(techResponse.data.id ?? '', this.techForm.get("ecosystem")?.value)
          }
          this.navigateToPreviousPage();
        },
        error: err => console.error(err)
      })
    }

  }
  async navigateToPreviousPage(): Promise<void> {
    await this.router.navigate(['..']);
  }

  onNameChange() {
    const slugFormField = this.techForm.get('slug');
    //only change slug if it hasn't been changed yet
    if (slugFormField?.dirty) return;
    
    const nameFormFieldValue: string = this.techForm.get('name')?.value;

    console.log(nameFormFieldValue)
    const sluggifiedName = sluggify(nameFormFieldValue);

    this.techForm.patchValue({
      slug: sluggifiedName
    });
  }

  selectTag(tagId: string): void {
    if (!this.tags || tagId === '-1') return;
    const foundTag: Tag = this.tags.find(tag => tag.id === tagId) ?? { name: '', id: tagId };
    this.selectedTags = [...(this.selectedTags ?? []), foundTag];
  }

  removeSelectedTag(tagId: string): void {
    this.selectedTags = this.selectedTags.filter(({ id }) => id !== tagId);
  }


  updateTags(techId: string) {
    const tagsToUpdate = this.selectedTags.map(({ id }) => id).filter((x): x is string => !!x);
    const currentTags = this.tech?.tags?.map(({ id }) => id).filter((x): x is string => !!x) ?? [];
    const newTags = tagsToUpdate.filter((id) => !currentTags.includes(id))
      .map((id) => this.techService.putTagsOnTech(techId, id));
    const removeTags = currentTags.filter(id => !tagsToUpdate.includes(id))
      .map(id => this.techService.deleteTagFromTech(techId, id));
    return [...newTags, ...removeTags];
  }

  updateEcoSystem(techId: string, ecosystemId: string) {
    let result: Observable<Object>[] = [];
    if (!this.tech?.ecosystems || this.tech.ecosystems.length === 0) {
      result.push(this.techService.putEcosystemOnTech(techId, ecosystemId));
    } else {
      const oldEcosystemId = this.tech.ecosystems[0]?.id;
      if (oldEcosystemId !== undefined && oldEcosystemId !== ecosystemId) {
        result = [
          this.techService.deleteEcosystemFromTech(techId, oldEcosystemId),
          this.techService.putEcosystemOnTech(techId, ecosystemId)
        ];
      }
    }
    return result;
  }

  async back(): Promise<void> {
    await this.router.navigate([".."]);
  }

  uploadImage() {
    const openFileUploadMessage = {
      messageType: 'openFileUpload',
      payload: {
        appName: 'tech',
      },
    };

    window.parent.postMessage(openFileUploadMessage, '*');
  }

  get allTags(): Tag[] {
    const selectedTagIds = this.selectedTags.map(({ id }) => id);
    return this.tags?.filter(({ id }) => !selectedTagIds.includes(id)) ?? [];
  }


}
