import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { TagGenre } from 'src/app/models/models';
import { TechService } from 'src/app/services/tech.service';
import { TagComponent } from 'src/app/tag/tag.component';

@Component({
  selector: 'app-tag-genre-delete-dialog',
  standalone: true,
  imports: [MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose, TagComponent],
  templateUrl: './tag-genre-delete-dialog.component.html',
  styleUrl: './tag-genre-delete-dialog.component.css'
})
export class TagGenreDeleteDialogComponent {
  readonly techService = inject(TechService);
  readonly dialogRef = inject(MatDialogRef<TagGenreDeleteDialogComponent>);
  readonly tagGenre = inject<TagGenre>(MAT_DIALOG_DATA);

  techsWithThisTag: null = null;
  isError: boolean = false;

  ngOnInit(): void {
   console.log(this.tagGenre)
  }

  delete() {
    if (!this.tagGenre.id) return
    this.techService.deleteTagGenre(this.tagGenre.id).subscribe({
      next: () => { this.dialogRef.close(this.tagGenre.id) },
      error: (error) => {
        console.error(error);
        this.isError = true
      },
    });
  }

  close() {
    this.dialogRef.close();
  }
}
