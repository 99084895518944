<div class="card h-100">
  <div class="card-header">Similar techs:</div>
  <div class="card-body">
    @if(!similarTechs){
    <p>Loading...</p>
    }@else if (similarTechs.length > 0) { @for (tech of similarTechs; track tech.id) {
    <div
      (click)="onClickGoTo(tech.slug)"
      class="btn btn-outline-secondary mt-1 w-100 d-flex align-items-center justify-content-between"
    >
      <span>{{ tech.name }} </span>
      <span class="bi bi-box-arrow-in-up-right h5 mb-0"></span>
    </div>
    } } @else {
    <p>No Similar Techs</p>
    }
  </div>
</div>
