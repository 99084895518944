<div class="container mt-3">
  <div class="row mb-3">
    <div class="col-3">
      <button class="btn btn-sm btn-outline-secondary" (click)="back()">Back</button>
    </div>
  </div>
</div>
<div class="d-flex container p-2 gap-4 align-items-stretch">
  <div class="card shadow-sm w-75 tech-card h-100">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-3 d-flex align-items-center my-auto">
          <a [href]="tech?.url" target="_blank">
            <img
              class="img-fluid object-fit-scale"
              width="200"
              height="200"
              [src]="tech?.imageUrl"
              [alt]="tech?.name"
            />
          </a>
        </div>
        <div class="col-9">
          <div class="d-flex gap-2 align-items-center">
            <h1>
              <a class="text-decoration-none" target="_blank" [href]="tech?.url">{{
                tech?.name
              }}</a>
            </h1>
            <h4>
              @if (tech?.ecosystems?.[0]) {
              <span class="badge bg-secondary my-2 me-2">
                {{ tech?.ecosystems?.[0]?.name }}
              </span>
              }
            </h4>
          </div>
          @if (sortedTags.length > 0) {
          <div class="d-flex flex-wrap gap-1">
            @for (tag of sortedTags; track tag) {
            <app-tag
              [name]="tag.name"
              [backgroundColor]="tag.tagGenre?.backgroundColor!"
              [textColor]="tag.tagGenre?.textColor!"
            >
            </app-tag>
            }
          </div>
          }
          <hr />
          <p class="card-text">{{ tech?.description }}</p>
        </div>
      </div>
      <div class="d-flex mt-5">
        <button class="btn btn-sm btn-outline-primary" (click)="onEditTech()">
          <span class="bi bi-pencil-fill"></span> Edit
        </button>
        <button
          class="btn btn-sm mx-1"
          [class.btn-danger]="!isArchived"
          [class.btn-success]="isArchived"
          (click)="onArchiveTech()"
          attr.aria-label="{{ isArchived ? 'Unarchive Tech' : 'Archive Tech' }}"
        >
          <span class="bi bi-archive"></span>
          {{ isArchived ? 'Unarchive' : 'Archive' }}
        </button>

        @if (isArchived) {
        <div class="ms-auto">
          <button class="btn btn-sm btn-danger" (click)="onDeleteTech()">Delete</button>
        </div>
        }
      </div>
    </div>
  </div>
  <div class="w-25">
    <app-similar-techs [name]="tech?.name" [id]="tech?.id"> </app-similar-techs>
  </div>
</div>
