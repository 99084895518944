import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Tag, TagGenre } from '../models/models';
import { TechService } from '../services/tech.service';
import { TagDeleteButtonComponent } from './tag-delete-button/tag-delete-button.component';


@Component({
  selector: 'app-tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TagDeleteButtonComponent,
  ],
})
export class TagEditComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private techService = inject(TechService);
  private formBuilder = inject(FormBuilder);

  title: string = 'Add Tag';
  id: string = '';
  tag?: Tag;
  tagGenres?: TagGenre[];
  tagForm: FormGroup;

  constructor() {
    this.tagForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      tagGenre: '',
    });
  }

  submit(): void {
    const updatedTag: Tag = {
      id: this.tag?.id,
      name: this.tagForm.get('name')?.value,
      tagGenreId: this.tagForm.get('tagGenre')?.value || null
    };

    if (this.id) {
      this.techService.putTag(this.id, updatedTag).subscribe({
        next: async (_) => this.router.navigate(['/tag']),
        error: (err) => console.error(err),
      });
    } else {
      this.techService.postTag(updatedTag).subscribe({
        next: () => this.router.navigate(['/tag']),
        error: (err) => console.error(err),
      });
    }
  }

  async back(): Promise<void> {
    await this.router.navigate(['/tag']);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.techService.getTagGenres().subscribe({
        next: (tagGenres) => {
          if (tagGenres.data) this.tagGenres = tagGenres.data;
        },
      });
      if (this.id) {
        this.title = 'Edit Tag';
        this.techService.getTagWithDetailById(this.id).subscribe({
          next: (tag) => {
            if (tag.data) {
              this.tag = tag.data;
              this.tagForm.setValue({
                name: tag.data.name,
                tagGenre: tag.data.tagGenre?.id ?? ''
              });
            }
            if (tag.error) {
              return;
            }
          },
          error: (e) => { },
        });
      }
    });
  }

  clearGenre() {
    this.tagForm.controls['tagGenre'].patchValue(null)
  }
}
