<div class="container mt-3 d-flex gap-2">
  <div class="container card shadow-sm p-3 w-75">
    <h3>{{ title }}</h3>
    <form [formGroup]="techForm" (submit)="submit()">
      <div class="form-group">
        <label for="name">Name</label><br />
        <input
          type="text"
          id="name"
          name="name"
          class="form-control"
          formControlName="name"
          (input)="onNameChange()"
          required
        />
        @if (techForm.get('name')?.invalid && (techForm.get('name')?.dirty ||
        techForm.get('name')?.touched)) {
        <div class="alert alert-danger">
          @if (techForm.get('name')?.errors?.['required']) {
          <div>Name is required.</div>
          } @if (techForm.get('name')?.errors?.['minlength']) {
          <div>Name must be at least 4 characters long.</div>
          }
        </div>
        }
        <label for="slug">Slug</label><br />
        <input
          type="text"
          id="slug"
          name="slug"
          class="form-control"
          formControlName="slug"
          required
        />
        @if (techForm.get('slug')?.invalid && (techForm.get('slug')?.dirty ||
        techForm.get('slug')?.touched)) {
        <div class="alert alert-danger">
          @if (techForm.get('slug')?.errors?.['required']) {
          <div>Slug is required.</div>
          } @if (techForm.get('slug')?.errors?.['minlength']) {
          <div>Slug must be at least 4 characters long.</div>
          } @if (techForm.get('slug')?.errors?.['pattern']) {
          <div>Slug can only contain lowercase, numbers, or -</div>
          }
        </div>
        }
        <label for="url">URL</label><br />
        <input type="text" id="url" name="url" class="form-control" formControlName="url" />
        @if (techForm.get('url')?.invalid && (techForm.get('url')?.dirty ||
        techForm.get('url')?.touched)) {
        <div class="alert alert-danger">
          @if (techForm.get('url')?.errors?.['required']) {
          <div>URL is required.</div>
          } @if (techForm.get('url')?.errors?.['pattern']) {
          <div>Don't be silly, enter a valid URL.</div>
          }
        </div>
        }

        <label for="description">Description</label><br />
        <textarea
          type="text"
          id="description"
          name="description"
          class="form-control"
          formControlName="description"
          rows="7"
        >
        </textarea>
        @if (techForm.get('description')?.invalid && (techForm.get('description')?.dirty ||
        techForm.get('description')?.touched)) {
        <div class="alert alert-danger">
          @if (techForm.get('description')?.errors?.['required']) {
          <div>Description is required.</div>
          } @if (techForm.get('description')?.errors?.['minlength']) {
          <div>Description must be at least 10 characters long.</div>
          }
        </div>
        }

        <label for="imageUrl">Image</label><br />
        <div class="d-flex gap-1">
          <button
            type="button"
            class="btn btn-primary btn-sm d-flex align-items-center gap-1"
            (click)="uploadImage()"
          >
            <span class="bi bi-cloud-upload"></span>
            Upload
          </button>
          <textarea
            type="text"
            id="imageUrl"
            name="imageUrl"
            class="form-control"
            formControlName="imageUrl"
            rows="1"
          >
          </textarea>
        </div>
        @if (techForm.errors?.['noFileOrUrl'] && (techForm.get('imageUrl')?.touched)) {
        <div class="alert alert-danger">
          Please provide either an image URL or upload an image file.
        </div>
        }

        <label for="tags" class="me-2">Tags</label>
         <div class="d-flex w-100 flex-wrap gap-1 mb-1">
          @for (selectedTag of selectedTags; track selectedTag) {
          <app-tag
            name="x {{selectedTag.name}}"
            [backgroundColor]="selectedTag.tagGenre?.backgroundColor!"
            [textColor]="selectedTag.tagGenre?.textColor!"
            (click)="removeSelectedTag(selectedTag?.id ?? '')">
          >
          </app-tag>
          }
        </div>

        <select id="tags" class="form-select" name="tags" #id (change)="selectTag(id.value)">
          <option value="-1">Select a tag</option>
          @for (tag of allTags; track tag) {
          <option [value]="tag.id">{{ tag.name }}</option>
          }
        </select>

        <label for="ecosystem">Ecosystem</label><br />
        <select
          class="form-select"
          name="ecosystem"
          formControlName="ecosystem"
          aria-label="Select ecosystem"
        >
          @for (ecosystem of ecosystems; track ecosystem) {
          <option [value]="ecosystem.id">
            {{ ecosystem.name }}
          </option>
          }
        </select>
        <br />

        <div class="w-100">
          <button
            class="btn btn-outline-primary mx-1 btn-sm"
            type="submit"
            [disabled]="techForm.invalid"
          >
            <span class="bi bi-save"></span>
            Save
          </button>
          <button
            class="btn btn-outline-secondary btn-sm"
            type="button"
            (click)="back()"
            [disabled]="isSubmitting"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="w-25">
    <app-similar-techs [id]="tech?.id" [name]="techForm.get('name')?.value"></app-similar-techs>
  </div>
</div>
